
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc } from '@/models/jsonapi'
  import { CareReceiver } from "@/models/care_receivers";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { careReceiverIconBar } from "@/utils/iconBars"

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed<number>(() => root.$store.state.care_receivers.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const queryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-created_at'
        }
        await root.$store.dispatch('care_receivers/load', queryParams)
        return root.$store.state.care_receivers.data
      }

      const columns = ref([
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.firstName",
          title: "Vorname",
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.lastName",
          title: "Nachname",
          sortable: true,
          options: {
            contentFunction: (data: any) => (data.item.attributes.lastName || data.item.id),
            hrefFunction: (data: any) => `/care_receivers/${data.item.id}`
          }
        },
        {
          component: () => import("@/components/shared/table/cells/IconCell.vue"),
          key: "attributes.careLevel",
          title: "Pflegegrad",
          sortable: true,
          options: {
            mappedIcons: [
              {
                value: '1',
                stack: [
                  {
                    title: 'Pflegegrad 1',
                    prefix: 'fas',
                    icon: 'thermometer-empty',
                    class: 'text-muted fa-2x'
                  }
                ]
              },
              {
                value: '2',
                stack: [
                  {
                    title: 'Pflegegrad 2',
                    prefix: 'fas',
                    icon: 'thermometer-quarter',
                    class: 'text-muted fa-2x'
                  }
                ]
              },
              {
                value: '3',
                stack: [
                  {
                    title: 'Pflegegrad 3',
                    prefix: 'fas',
                    icon: 'thermometer-half',
                    class: 'text-muted fa-2x'
                  }
                ]
              },
              {
                value: '4',
                stack: [
                  {
                    title: 'Pflegegrad 4',
                    prefix: 'fas',
                    icon: 'thermometer-three-quarters',
                    class: 'text-muted fa-2x'
                  }
                ]
              },
              {
                value: '5',
                stack: [
                  {
                    title: 'Pflegegrad 5',
                    prefix: 'fas',
                    icon: 'thermometer-full',
                    class: 'text-muted fa-2x'
                  }
                ]
              },
              {
                value: 'kein Pflegegrad',
                stack: [
                  {
                    title: 'kein Pflegegrad',
                    prefix: 'fas',
                    icon: 'times',
                    class: 'text-muted fa-2x'
                  }
                ]
              }
            ]
          }
        },
        {
          component: () => import("@/components/shared/table/cells/MultipleIconsCell.vue"),
          label: "Icons",
          title: "Icons",
          key: 'attributes',
          options: {
            icons: careReceiverIconBar
          }
        },
        // {
        //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        //   type: "Def",
        //   key: "householdFamilyNames",
        //   title: "Vertrag mit",
        //   sortable: true,
        //   options: {
        //     contentFunction: (data:any) => {
        //       const crs = data.item.attributes.household.attributes.care_receivers
        //       const familyName = crs.map(cr => cr.attributes.address.attributes.lastName).join(', ').trim()
        //       if (crs.length == 0) {return 'ERROR! Keine Patienten'}
        //       return familyName !== '' ? familyName : 'Keiner vorhanden'
        //     },
        //     hrefFunction: (data: any) => `/care_givers/${data.item.id}`
        //   }
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.agency.attributes.name",
        //   title: "Agentur",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.clientSigned",
        //   title: "Sig. Kd.",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.agencySigned",
        //   title: "Sig. Ag.",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.active",
        //   title: "Aktiv",
        //   sortable: true,
        // },
        // {
        //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        //   key: "attributes.archived",
        //   title: "Archiviert",
        //   sortable: true,
        // },
      ])

      const titleActions = []

      return {
        getItems,
        totalItems,
        columns,
        titleActions
      }
    }
  })
